<template>
  <div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Credit Note Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.Code }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer Return</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.SalesReturnCode == "" ?  "- (Credit Note Manual)":selected.SalesReturnCode }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.CustomerCode }} - {{ selected.CustomerName }}
      </div>
    </div>
    <template v-if="selected.SalesReturnCode==''">
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Total Value</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{priceFormat(selected.TotalValue)}}<br>
      </div>
    </div>
    </template>
    <vs-divider v-if="selected.SalesReturnCode != ''" style="width: 100%; margin-left: 2%">List Item</vs-divider>

    <table v-if="selected.SalesReturnCode!=''" class="table">
      <thead>
        <th>Item Code</th>
        <th>Item Name</th>
        <th>Qty</th>
        <th>SO Value</th>
        <th>CN Value</th>
      </thead>
      <template v-for="line in lines">
        <tr v-bind:key="line.ID">
          <td>{{ line.ItemCode }}</td>
          <td>{{ line.ItemName }}</td>
          <td>{{ line.Qty }}</td>
          <td>
            {{ priceFormat(line.TotalSo.toString())}}
          </td>
          <td>{{priceFormat(line.Total.toString())}}</td>
        </tr>
      </template>
    </table>
  </div>
</template>
<script>
import { dataCreditNote } from "../../../../../services/api/credit_note";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
  },
  components: {},
  data() {
    return {
      lines: [],
    };
  },
  computed: {},
  watch: {
    selected() {
      console.log(this.selected);
    },
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("MM/DD/YYYY hh:mm");
      }
    },
    addValidate(ID) {
      console.log(this.validate.length);
      if (this.validate.includes(ID)) {
        this.validate.splice(this.validate.indexOf(ID), 1);
      } else {
        this.validate.push(ID);
      }
    },
    addAllValidate() {
      console.log(this.data, this.validateAll);
      if (this.validateAll == false) {
        for (var i in this.data) {
          if (!this.validate.includes(this.data[i].ID)) {
            this.validate.push(this.data[i].ID);
          }
        }
      } else {
        this.validate = [];
      }
    },
    reloadData() {
      this.$vs.loading();
      const creditNote = dataCreditNote(this.selected);
      creditNote.then((r) => {
        this.$vs.loading.close();
        console.log(r);
        // this.lines = r.data.creditNoteLine;
        this.lines = []
        r.data.creditNoteLine.map(v => {
          // v.PriceSo = v.PriceSo.toString().split(".")[0]
          v.TotalSo = parseFloat(v.PriceSo * v.Qty) +  parseFloat(v.PriceSo * v.Qty * v.TaxRate / 100)
          v.TotalSo = v.TotalSo.toFixed(2).toString().replace('.', ',')
          v.Total = v.Total.toFixed(2).toString().replace('.', ',')
          this.lines.push(v)
        })
      });
    },
    handleView(selected) {
      console.log(selected);
      this.$vs.loading();
    },
    handleDelete(selected) {
      console.log(selected);
    },
    formatPrice(angka, prefix = "") {
      console.log(angka);
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      split[0] = parseInt(split[0]).toString();
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>