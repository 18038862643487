<template>
    <div>
        <vx-card title="Approval Credit Note">
            <vs-tabs :color="colorx" v-model="tabs">
                <vs-tab @click="colorx = 'warning'" label="Waiting Approval">
                    <div class="con-tab-ejemplo">
                        <waiting/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Approved">
                    <div class="con-tab-ejemplo">
                        <approved/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Rejected">
                    <div class="con-tab-ejemplo">
                        <rejected/>
                    </div>
                </vs-tab>
                
            </vs-tabs>
        </vx-card>
    </div>
</template>
<script>

import waiting from "./waiting_approval.vue";
import approved from "./approved.vue";
import rejected from "./rejected.vue";


export default {
    components: {
        rejected,
        approved,
        waiting
    },
    data:() => ({
        colorx: "danger",
        tabs: 0,
    })
}
</script>